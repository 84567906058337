import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import _flow from 'lodash/flow';
import _get from 'lodash/get';
import changeCase from 'change-case';

import {
  AccordionPanel,
  CopyMediaGroup,
  PageHelmet,
  PodcastHeader,
  SmallHeading,
  ListenOnCard,
  ListenOnLink,
  PodcastsInSeason,
  PodcastTranscript,
  PodcastRelatedContent,
  PodcastEmbed,
  PodcastFeaturedImage,
  PodcastEpisodeMeta,
} from 'components';
import { Box, Row, theme, Column } from 'design-system';
import { BlogTemplateWrapper } from 'partials/blog-shared';
import { getContentByCriteria, formatTitle } from 'utils';

const buildPlatformLinkSpecs = (platformLinks) =>
  platformLinks.map(({ props: { platform, url } }) => ({
    platform,
    href: url,
    linkTitle: `Listen to this podcast on ${changeCase.titleCase(platform)}`,
  }));

const buildLinkComponentsFromSpecs = (specs) =>
  specs.map((spec) => (
    <ListenOnLink key={`listenOnLink${spec.href}`} {...spec} />
  ));

const generatePlatformListenOnLinks = _flow(
  buildPlatformLinkSpecs,
  buildLinkComponentsFromSpecs,
);

const generateBasePodcastMeta = ({ heading, description, featuredImage }) => ({
  title: heading,
  description,
  image: _get(featuredImage, 'props.file.url', null),
});

export default class PodcastSingleTemplate extends React.Component {
  componentDidMount() {
    const { data, location } = this.props;
    const podcast = JSON.parse(data.contentfulPodcastTree.componentTree);
    const { props: podcastProps } = podcast;
    const { heading } = podcastProps;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Custom-PageView',
      pageType: 'Podcasts',
      pagePath: `${location.pathname}`,
      pageTitle: `${formatTitle(heading)}`,
    });
  }

  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const podcast = JSON.parse(data.contentfulPodcastTree.componentTree);
    const { props: podcastProps } = podcast;
    const {
      heading,
      showNotes,
      cta,
      embedCode,
      featuredImage,
      transcript,
      publishedDate,
      series,
      podcastSeries,
      podcastSeason,
      episodeId,
      tags = [],
      people = [],
      platformLinks,
    } = podcastProps;
    const { props: podcastSeasonProps } = podcastSeason;
    const featuredImageSrc = _get(featuredImage, 'props.file.url', null);

    const listenOnLinks = platformLinks
      ? generatePlatformListenOnLinks(platformLinks)
      : null;

    const relatedContent = getContentByCriteria(
      {
        filters: [
          {
            type: 'byAllTags',
            value: tags.map(({ props }) => props.slug),
          },
        ],
        contentSlug: slug,
      },
      data,
    );

    return (
      <BlogTemplateWrapper mx={[]}>
        <PageHelmet
          {...generateBasePodcastMeta(podcastProps)}
          extraMeta={<PodcastEpisodeMeta data={podcastProps} />}
        />
        <Box px={theme.doubleGutters}>
          <PodcastHeader
            title={heading}
            publishedDate={moment(publishedDate).format('MMMM D, YYYY')}
            tags={tags.map(({ props }) => props)}
            series={series}
            podcastSeries={podcastSeries}
            seasonNumber={podcastSeasonProps.number}
            episodeNumber={episodeId}
            people={people.map(({ props }) => props.name)}
          />
        </Box>
        <Row mx={theme.gutters}>
          <Column
            width={[1, 1, 2 / 3]}
            mb={[theme.verticalSpace.medium, theme.verticalSpace.medium, 0]}
          >
            <Box
              width={[1]}
              display="flex"
              flexWrap={['wrap', 'nowrap']}
              mb={theme.verticalSpace.medium}
            >
              {featuredImageSrc !== null && (
                <Box
                  height={['auto', '264px']}
                  width={['100%', '264px']}
                  flex={['0 0 100%', '0 0 264px']}
                  mb={[theme.verticalSpace.medium, theme.verticalSpace.none]}
                >
                  <PodcastFeaturedImage src={featuredImageSrc} />
                </Box>
              )}

              <PodcastEmbed
                embedCode={embedCode}
                hideEmbedImage={featuredImageSrc !== null}
                style={{
                  flex: '1 1 auto',
                  padding: `0 ${theme.gutters[1]}px`,
                  backgroundColor: theme.colors.brand[2],
                }}
              />
            </Box>
            {listenOnLinks && (
              <Box width={[1]} mb={[theme.verticalSpace.xSmall]}>
                <ListenOnCard listenOnLinks={listenOnLinks} />
              </Box>
            )}
            <Box
              // the actual margin we want is less the margin inside of the CMG
              mb={
                cta
                  ? [theme.verticalSpace.medium]
                  : [theme.verticalSpace.medium - theme.verticalSpace.small]
              }
              mx={theme.gutters.map((gutter) => gutter * -1)}
            >
              <CopyMediaGroup
                paragraph={showNotes}
                cta={cta}
                useFullTextMarkdown
                hideMediaColumn
              />
            </Box>
            {transcript && transcript.trim() && (
              <div style={{ borderTop: `1px solid ${theme.colors.uiDivider}` }}>
                <AccordionPanel
                  summary={
                    <SmallHeading
                      text="Episode Transcript"
                      fontFamily={theme.fonts.regular}
                    />
                  }
                  details={<PodcastTranscript transcript={transcript} />}
                />
              </div>
            )}
          </Column>
          <Column width={[1, 1, 1 / 3]} mb={[theme.verticalSpace.medium, 0]}>
            <Box pl={[0, 0, '16.66667%', '25%']}>
              <PodcastsInSeason
                currentPodcast={podcast}
                podcastsInSeasonQuery={pageContext.queryData}
              />
            </Box>
          </Column>
          <Column width={[1]}>
            <Box
              mt={[theme.verticalSpace.medium, theme.verticalSpace.mediumLarge]}
            >
              <PodcastRelatedContent
                relatedContent={relatedContent}
                cardTitlePaddingBottom={[theme.verticalSpace.xSmall]}
                forceColumnWidthToThirds
              />
            </Box>
          </Column>
        </Row>
      </BlogTemplateWrapper>
    );
  }
}

export const podcastSingleTemplateQuery = graphql`
  query PodcastSingleTemplateQuery(
    $slug: String!
    $tags: [String]
    $tagsRegex: String
  ) {
    contentfulPodcastTree(slug: { eq: $slug }) {
      id
      componentTree
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { in: $tags } } } }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allContentfulPodcastTree(
      filter: { componentTree: { regex: $tagsRegex } }
      limit: 3
    ) {
      edges {
        node {
          slug
          componentTree
        }
      }
    }
  }
`;
